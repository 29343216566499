import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { FaGithub, FaLinkedinIn, FaMediumM, FaTwitter } from 'react-icons/fa'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import { externalLink } from 'constants/paths'

const Home = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => {
      return (
        <Layout>
          <SEO
            isBlogPost={false}
            lang={locale}
            title={formatMessage({ id: 'home.title' })}
            description={formatMessage({ id: 'meta.description' })}
            keywords={formatMessage({ id: 'meta.keywords' })}
            url={formatMessage({ id: 'meta.url' })}
          />
          <div className='container'>
            <div className='columns is-mobile'>
              <div className='column'>
                <figure className='photo image is-128x128'>
                  <StaticQuery
                    query={graphql`
                      query {
                        file(relativePath: { eq: "mihirpipermitwala.png" }) {
                          absolutePath
                          accessTime
                          childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                              ...GatsbyImageSharpFluid
                              src
                            }
                          }
                          name
                        }
                      }
                    `}
                    render={data => (
                      <img
                        src={data.file.childImageSharp.fluid.src}
                        alt={data.file.name || 'Mihir Pipermitwala'}
                        style={{ borderRadius: '100%' }}
                      />
                    )}
                  />
                </figure>
                <h1 className='title has-text-centered has-text-light'>
                  <FormattedMessage id='profileName' />
                </h1>
                <h2 className='subtitle has-text-centered has-text-warning'>
                  <FormattedMessage id='home.headline' />
                </h2>
              </div>
            </div>
            <div className='has-text-centered is-mobile'>
              <a href={externalLink.github} aria-label='github'>
                <span className='icon is-large'>
                  <FaGithub className='fab fa-2x' />
                </span>
              </a>
              <a href={externalLink.twitter} aria-label='twitter'>
                <span className='icon is-large'>
                  <FaTwitter className='fab fa-2x' />
                </span>
              </a>
              <a href={externalLink.medium} aria-label='medium'>
                <span className='icon is-large'>
                  <FaMediumM className='fab fa-2x' />
                </span>
              </a>
              <a href={externalLink.linkedin} aria-label='linkedin'>
                <span className='icon is-large'>
                  <FaLinkedinIn className='fab fa-2x' />
                </span>
              </a>
            </div>
          </div>
          <section className='section is-size-4-desktop is-size-5-touch'>
            <div className='container content'>
              <h4 className='title has-text-light'>
                <FormattedMessage id='home.greet' />{' '}
                <span className='emoji' role='img' aria-label='wave'>
                  👋
                </span>
              </h4>
              <p>
                <FormattedMessage id='home.about' />
              </p>
              <p>
                <FormattedMessage id='checkOut' />{' '}
                <Link to={getPath('blogs')}>
                  <FormattedMessage id='stack.posts' />
                </Link>
                {'.'}
              </p>
            </div>
          </section>
        </Layout>
      )
    }}
  </IntlProvider>
)

Home.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Home
